import React from "react";

import { firestore } from "../../firebase/firebase.utils";
import "./obituary.css";

class Obituary extends React.Component {
  unsubscribe = null;
  constructor(prop) {
    super(prop);

    this.state = {
      data: []
    };
  }

  componentDidMount = () => {
    try {
      const accountRef = firestore.collection("accounts");
      this.unsubscribe = accountRef.onSnapshot(snapshot => {
        const accountsMap = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id };
        });
        this.setState({ data: accountsMap });
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount = async () => {
    this.unsubscribe();
  };

  render() {
    return this.state.data.map(item => {
      return (
        <div className="root">
          <div>{item.name}</div>
          <div>
            {item.dateOfBirth} - {item.dateOfDeath}
          </div>
          <div>{item.obituary}</div>
          <div>{item.location === 34 ? "Hamilton" : "Burlington"}</div>
        </div>
      );
    });
  }
}

export default Obituary;
