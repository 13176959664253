import React, { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import TextField from "@material-ui/core/TextField";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Button from "@material-ui/core/Button";

import { firestore } from "../../firebase/firebase.utils";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class Accounts extends React.Component {
  unsubscribe = null;
  constructor(prop) {
    super(prop);

    this.state = {
      columns: [
        // {
        //   field: "url",
        //   Title: "Avatar",
        //   render: rowData => {
        //     return rowData !== undefined && rowData.url !== undefined ? (
        //       <img
        //         src={rowData.url}
        //         style={{ width: 50, borderRadius: "50%" }}
        //         alt={rowData.name}
        //       />
        //     ) : (
        //       <React.Fragment>
        //         <input
        //           accept="image/*"
        //           style={{ display: "none" }}
        //           id="contained-button-file"
        //           multiple
        //           type="file"
        //           onChange={this.handChange}
        //         />
        //         <label htmlFor="contained-button-file">
        //           <Button variant="raised" component="span">
        //             <PhotoCameraIcon style={{ width: 50 }} />
        //           </Button>
        //         </label>
        //       </React.Fragment>
        //     );
        //   },
        //   editable: "never"
        // },
        { title: "Name", field: "name" },
        {
          title: "Obituary",
          field: "obituary",
          editComponent: props => (
            <textarea
              onChange={e => props.onChange(e.target.value)}
              style={{ width: "100%", minHeight: "100px" }}
            >
              {props.value}
            </textarea>
          )
        },
        {
          title: "DoB",
          field: "dateOfBirth",
          editComponent: props => (
            <TextField
              id="dateOfBirth"
              type="date"
              defaultValue={props.value}
              onChange={e => props.onChange(e.target.value)}
            />
          )
        },
        {
          title: "DoD",
          field: "dateOfDeath",
          editComponent: props => (
            <TextField
              id="dateOfDeath"
              type="date"
              defaultValue={props.value}
              onChange={e => props.onChange(e.target.value)}
            />
          )
        },
        {
          title: "Location",
          field: "location",
          lookup: { 34: "Hamilton", 63: "Burlington" }
        }
      ],
      data: [],
      image: null,
      url: null
    };
  }

  componentDidMount = () => {
    try {
      const accountRef = firestore.collection("accounts");
      this.unsubscribe = accountRef.onSnapshot(snapshot => {
        const accountsMap = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id };
        });
        this.setState({ data: accountsMap });
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount = async () => {
    this.unsubscribe();
  };

  addAccount = async account => {
    const createdAt = new Date();
    try {
      await firestore.collection("accounts").add({
        createdAt,
        ...account
      });
    } catch (error) {
      console.error("error creating account", error.message);
    }
  };

  removeAccount = async account => {
    try {
      await firestore
        .collection("accounts")
        .doc(account.id)
        .delete()
        .then(
          this.setState(prevState => {
            let data = [...prevState.data];
            data.splice(data.indexOf(account), 1);
            return { ...prevState, data };
          })
        );
    } catch (error) {
      console.error("error creating account", error.message);
    }
  };

  updateAccount = async account => {
    try {
      await firestore
        .collection("accounts")
        .doc(account.id)
        .update(account);
    } catch (error) {
      console.error("error creating account", error.message);
    }
  };

  guidGenerator = () => {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4() +
      ".jpg"
    );
  };

  render() {
    return (
      <MaterialTable
        title="Accounts"
        columns={this.state.columns}
        data={this.state.data}
        icons={tableIcons}
        options={{ pageSize: 10 }}
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                this.addAccount(newData);
                resolve();
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                if (oldData) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    data[data.indexOf(oldData)] = newData;
                    return { ...prevState, data };
                  });
                }
              }, 600);
              this.updateAccount(newData);
            }),
          onRowDelete: oldData => {
            new Promise(resolve => {
              setTimeout(() => {
                this.removeAccount(oldData);
                // this.setState(prevState => {
                //   let data = [...prevState.data];
                //   data.splice(data.indexOf(oldData), 1);
                //   return { ...prevState, data };
                // });
                resolve();
              }, 600);
            });
          }
        }}
      />
    );
  }
}

export default Accounts;
