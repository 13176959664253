import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";

import createRootReducer from "./root-reducer";

export const history = createBrowserHistory();

const middlewares = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

export const store = createStore(
  createRootReducer(history),
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);
