import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Signin from "../pages/signin/signin";
import Signup from "../pages/signup/signup";
import Obituary from "../pages/obituary/obituary";
import Dashboard from "../pages/dashboard/dashboard";

import { setCurrentUser } from "../redux/user/user.actions";
import { getCurrentUser } from "../redux/user/user.selectors";

import { auth, createUserProfileDocument } from "../firebase/firebase.utils";

class App extends React.Component {
  unsubscribeFromAuth = null;

  componentDidMount = () => {
    const { setCurrentUser } = this.props;

    try {
      this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
        if (userAuth) {
          const userRef = await createUserProfileDocument(userAuth);

          userRef.onSnapshot(snapShot => {
            setCurrentUser({ id: snapShot.id, ...snapShot.data() });
          });
        }
        setCurrentUser(userAuth);
      });
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount = async () => {
    this.unsubscribeFromAuth();
  };
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              this.props.currentUser ? <Redirect to="/dashboard" /> : <Signin />
            }
          />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/obituary" component={Obituary} />
          <Route
            exact
            path="/dashboard"
            render={() =>
              this.props.currentUser ? <Dashboard /> : <Redirect to="/" />
            }
          />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
